/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef, useState } from 'react';
import { FaPlayCircle } from 'react-icons/fa';
import styles from './Video.module.css';
import classConcat from '../../util/ClassConcat';
import thumbnail from '../../assets/thumbnail.png';
import useOnScreen from '../../hooks/useOnScreen';
import clsx from 'clsx';

const Video = ({ src, type, poster, signup, myRef, id, hideControls, videoPreview, costpage,...rest }) => {
  const [play, setPlay] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const videoRef = useRef(null);
  const isVisible = useOnScreen(videoRef);
  let timeout = null;
console.log({vidRef:videoRef?.current})
  useEffect(() => {
    if (!videoPreview && !isVisible) {
      const video = videoRef?.current;

      if (!video?.paused) {
        setPlay(false);
        video?.pause()
         
      }
    }
  }, [isVisible]);

  useEffect(() => {
    if (!videoRef?.current?.paused) {
      setPlay(true);
    }
  }, [videoRef?.current?.paused]);
  const handlePlay = () => {
    const video = videoRef?.current;
    if (video) {
      if (video.paused) {
        setPlay(true);
        timeout = setTimeout(() => {
          video?.play();
          setShowControls(true);
        }, 110);
      } else {
        setPlay(false);
        timeout = setTimeout(() => {
          video?.pause();
          setShowControls(true);
        }, 100);
      }
      // return false;
    }
    // return false;
  };
  useEffect(() => {
    if (hideControls) {
      setShowControls(false);
    }
  }, [hideControls]);
  useEffect(() => {
    if (videoPreview) {
      const video = videoRef?.current;
      if (video) {
        if (video?.paused) {
          setPlay(true);
          timeout = setTimeout(() => {
            video?.play()
            setShowControls(true);
          }, 110);
        } else {
          setPlay(false);
          timeout = setTimeout(() => {
            video?.pause()
            setShowControls(true);
          }, 100);
        }
      }
    }
  }, []);
  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return (
    <>
      {signup ? (
        <div className={clsx(styles.videoHolder, styles.costpage)} ref={myRef} onClick={handlePlay}>
          <video
            id={id}
            ref={videoRef}
            poster={poster || thumbnail}
            controls={showControls}
            src={src}
            onPlay={() => {setPlay(true)}}
            onPause={()=>{
              setPlay(false)
            }}
            type='video/mp4'
            {...rest}
          ></video>
          {!play && (
            <div className={styles.play} onClick={handlePlay}>
              <FaPlayCircle />
            </div>
          )}
        </div>
      ) : (
        <section>
          <div className={classConcat('container', styles.sectionContainer, styles.container)}>
            <video className={styles.video}>
              <source src={src} type={type} />
            </video>
          </div>
        </section>
      )}
    </>
  );
};
export default Video;
