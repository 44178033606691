/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from '@reach/router';
import clsx from 'clsx';
import { navigate } from 'gatsby';
import { FaPlayCircle } from 'react-icons/fa';
import FreeFooter from '../../components/common/Footer/FreeFooter/FreeFooter';
import Head from '../../components/Head/Head';
import Header from '../../components/Header/Header';
import styles from '../../styles/pages.module.css';
import team from '../../assets/developers.jpg';
import usc from '../../assets/usc.png';
import blackRock from '../../assets/black_rock.png';
import madras from '../../assets/madras.png';
import mellonBank from '../../assets/mellon_bank.png';
import shoutoutLa from '../../assets/shoutoutLa.png';
import Reciept from '../../components/Reciept';
import parsePricingMesg from '../../functions/pricing/parsePricingMesg/parsePricingMesg';
import PricingLayout from '../../layouts/pricing';
import useClidParser from '../../hooks/pricing/useClidParser';
import Video from '../../components/Video/video';
import ModalNew from '../../components/ModalNew';

const Pricing = () => {
    const videoRef = useRef(null);
    const location = useLocation();
    const [msg, setMsg] = useState('');
    const [showVideo, setShowVideo] = useState(false);
    useClidParser();

    useEffect(() => {
      const mesg = parsePricingMesg(location);
      setMsg(mesg);
    }, []);

    return (
      <>
        {msg ? (
          <PricingLayout />
        ) : (
          <>
            <Head />
            <Header pageId={location?.pathname} hideLinks/>
            <section
              className={clsx(styles.pricingSection)}
            >
              <div className={clsx(styles.imgHolder)}>
                <img src="https://media.opzoom.com/app.opzoom.com/cost-to-make-an-app/landing/cover.jpeg" alt="home" />
              </div>
              {showVideo && (
              <ModalNew isOpen={showVideo} setIsOpen={setShowVideo} textSm videoPreview showVideo={showVideo}>
                <Video
                // id={Math.random()}
                  signup
                  rcPlayer
                  hideControls
                  videoPreview
                  costpage
                  myRef={videoRef}
                  src="https://media.opzoom.com/app.opzoom.com/cost-to-make-an-app/landing/video.mp4"
                  type="video/mp4"
                  poster="https://media.opzoom.com/app.opzoom.com/cost-to-make-an-app/landing/cover.jpeg"
                />
              </ModalNew>
              )}
              {/* <div className={styles.videoHolder}> */}

              {/* </div> */}
              {/* <div className="container d-flex justiy-content-start"> */}

              <div
                className={styles.play_holder}
                onClick={() => {
                  setShowVideo(true);
                }}
              >
                <FaPlayCircle />
              </div>
              {/* <div className={styles.textHolder}>
                <div className={styles.textWrap}>
                  <h1 className={styles.title}>
                    5 Stage Cost & Time
                    {' '}
                    <br />
                    To Build Your
                    {' '}
                    <br />
                    Web / Mobile App!
                  </h1>
                  <p>
                    Chose options for your app to get
                    {' '}
                    <br />
                    <strong>Detailed Plan</strong>
                    {' '}
                    in ONE minute with
                    {' '}
                    <br />
                    5 Stage
                    {' '}
                    <strong>Cost & Time</strong>
                    {' '}
                    breakdown
                    <br />
                    for Your App's development.
                  </p>
                  <div className={styles.btnWrap}>
                    <button
                      className={clsx('btn-quiz')}
                      onClick={() => {
                        navigate('/cost-to-make-an-app/platforms');
                      }}
                    >
                      click here to continue
                      {' '}
                      <span>&gt;</span>
                    </button>
                  </div>
                </div>
              </div> */}
              {/* </div> */}
              {/* <div className={styles.heroText}>
              <h4>
                Sample Question
              </h4>
              <p>
                Pick Your App's Type:
                <br />
                <p>
                  iOS, Android or Web App?
                </p>
              </p>
              <div className={styles.btnWrap}>
                <button
                  className={clsx('btn-quiz')}
                  onClick={() => {
                    navigate('/cost-to-make-an-app/platforms');
                  }}
                  type="button"
                >
                  Select Options >
                </button>
              </div>
            </div> */}
            </section>
            <div className={styles.heroText}>
<p>
  To get your app's cost and time estimate along with sample documents, please pick your options:
</p>
              <h4>Sample Question</h4>
              <p>
                Pick Your App's Type:
                <br />
                <p>iOS, Android or Web App?</p>
              </p>
              <div className={styles.btnWrap}>
                <button
                  className={clsx('btn-quiz')}
                  onClick={() => {
                    navigate('/cost-to-make-an-app/platforms/');
                  }}
                  type="button"
                >
                  Select Options &gt;
                </button>
              </div>
            </div>
            <section className={styles.logoSection}>
              <div className="container d-flex align-items-center">
                <div className={clsx('row', styles.myrow)}>
                  <div className="col-12 col-md-3 col-lg-2 d-flex align-items-center justify-content-center">
                    <div className={styles.logoHolder}>
                      <img src={usc} alt="logo" />
                    </div>
                  </div>
                  <div className="col-12 col-md-3 col-lg-2 d-flex align-items-center justify-content-center">
                    <div className={styles.logoHolder}>
                      <img src={blackRock} alt="logo" />
                    </div>
                  </div>
                  <div className="col-12 col-md-3 col-lg-2 d-flex align-items-center justify-content-center">
                    <div className={styles.logoHolder}>
                      <img src={madras} alt="logo" />
                    </div>
                  </div>
                  <div className="col-12 col-md-3 col-lg-2 d-flex align-items-center justify-content-center">
                    <div className={styles.logoHolder}>
                      <img src={mellonBank} alt="logo" />
                    </div>
                  </div>
                  <div className="col-12 col-md-3 col-lg-2 d-flex align-items-center justify-content-center">
                    <div className={styles.logoHolder}>
                      <img src={shoutoutLa} alt="logo" />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className={styles.coachingSection}>
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <img src={team} alt="laptop" />
                  </div>
                  <div className="col-md-6">
                    <h2>Why You'll Love This App Plan.</h2>
                    <p className="mb-3">
                      This is
                      {' '}
                      <b>the most detailed</b>
                      {' '}
                      1 minute
                      {' '}
                      <b>Cost & Time</b>
                      {' '}
                      Plan
                      <ul>
                        <li>Get a detailed plan of action to build your app.</li>
                        <li>Get Sample Documents from Each Step</li>
                        <li>Learn How People use each Document</li>
                        <li>Estimate from developing 100+ apps</li>
                      </ul>
                    </p>
                    {/* <h2>What You Will Love About Us</h2>
                    We value your success because your success is our success.
                    <br />
                    You can expect the following when you work with us:
                    <ul>
                      <li>
                        <b>Speed</b>
                        {' '}
                        Your first app can be published in
                        {' '}
                        <b>6 weeks</b>
                        .
                      </li>
                      <li>
                        <b>Price</b>
                        {' '}
                        We match or beat prices for comparable apps.
                      </li>
                      <li>
                        <b>Quality</b>
                        {' '}
                        We will fix any bugs or quality issues.
                      </li>
                      <li>
                        <b>AWS</b>
                        {' '}
                        Your app will use AWS, the #1 Cloud Platform.
                      </li>
                    </ul> */}
                  </div>
                </div>
              </div>
            </section>
            {/* <section className={styles.quizBox}>
              <div className="container">
                <div className="textHolder">
                  <h2>What is the time & cost to develop your App?</h2>
                  <p>Get an idea of your App's Size</p>
                  <div className={styles.btnWrap}>
                    <button className={clsx(styles.btnSize)} type="button">
                      Small (S)
                    </button>
                    <button className={clsx(styles.btnSize)} type="button">
                      Medium (M)
                    </button>
                    <button className={clsx(styles.btnSize)} type="button">
                      Large (L)
                    </button>
                    <button className={clsx(styles.btnSize)} type="button">
                      Xtra Large (XL)
                    </button>
                  </div>
                  <p>
                    Take This FREE
                    {' '}
                    <b>1 Minute</b>
                    {' '}
                    Assessment To
                    {' '}
                    <br />
                    Find Out The Size Class of your App
                    {' '}
                    <br />
                  </p>
                </div>
                <div className={styles.btnWrap}>
                  <button
                    className="btn-quiz"
                    onClick={() => {
                      navigate('/cost-to-make-an-app/platforms');
                    }}
                  >
                    Get Your App Development Plan
                    {' '}
                    <span>&gt;</span>
                  </button>
                </div>
              </div>
            </section> */}
            <section className={styles.reportSection}>
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-6 d-flex justify-content-center">
                    <h1 className="text-center text-md-left">
                      Get This Summary
                      <br />
                      of Costs & Time
                      {' '}
                      <br />
                      And a Detailed Plan
                      <br />
                      With Sample Designs
                      <br />
                      AND Your Strategy
                      <br />
                      To Get To Success.
                    </h1>
                  </div>
                  <div className="col-12 col-md-6 d-flex justify-content-center mt-2 mt-md-0 mb-3">
                    <div>
                      <Reciept type="sample" />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className={styles.quizSection}>
              <div className="container text-center">
                <h2>
                  Pick Your App's Options
                  <br />
                  Get a detailed
                  {' '}
                  <strong>FIVE stage cost split</strong>
                  <br />
                  and
                  {' '}
                  <strong>plan of action</strong>
                  <p>
                    .. in
                    {' '}
                    <b>1 minute</b>
                    {' '}
                    !
                  </p>
                </h2>

                <div className={styles.btnWrap}>
                  <button
                    className={clsx('btn-quiz')}
                    onClick={() => {
                      navigate('/cost-to-make-an-app/platforms/');
                    }}
                  >
                    Get the Detailed Cost & Plan
                    <span>&gt;</span>
                  </button>
                </div>
              </div>
            </section>
            {/* <FreeFooter /> */}
          </>
        )}
      </>
    );

};

export default Pricing;
