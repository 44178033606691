import React, { useState } from 'react';
import styles from './styles.module.css';
import logo from '../../assets/logo.png';
import clsx from 'clsx';

const Reciept = ({ design, development, type }) => {
  const [total] = useState(design + development);
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return (
    <div className={styles.container}>
      <div className={styles.receipt_box}>
        <div className={styles.head}>
          <div className={styles.logo}>
            <img src={logo} alt='logo' />
          </div>
          <div className={styles.number}>
            <div className={styles.date}>OPZOOM</div>
          </div>
        </div>
        <div className={styles.recieptBody}>
          <div className={styles.info}>
            <div className={styles.welcome}>
              {/* Hi, <span className={styles.username}>Kai Cheng</span> */}
            </div>
            {/* <p>You've purchased (3) items in our store</p> */}
          </div>
          <div className={styles.cart}>
            <div className={styles.title}>Phases</div>
            <div className={styles.content}>
              <p>Stages of app development.</p>
              <ul className={styles.cart_list}>
                <li className={styles.cart_item}>
                  <div className='d-flex'>
                    <span className={clsx(styles.index, 'd-block')}>
                      Phase 1: (20 minutes)
                    </span>
                    <span className={styles.week}>
                      ( {type === 'sample' ? '__' : 1} Week)
                    </span>
                  </div>
                  <div className='d-flex'>
                    <span className={styles.name}>Development Plan</span>
                    <span className={styles.price}>
                      {type === 'sample' ? '$_____' : 'FREE'}
                    </span>
                  </div>
                </li>
                <li className={styles.cart_item}>
                  <div className='d-flex'>
                    <span className={clsx(styles.index, 'd-block')}>
                      Phase 2: (week 1)
                    </span>
                    <span className={styles.week}>
                      ( {type === 'sample' ? '__' : 1} Week)
                    </span>
                  </div>
                  <div className='d-flex'>
                    <span className={styles.name}>Design Outline</span>
                    <span className={styles.price}>
                      {type === 'sample' ? '$_____' : formatter.format(500)}
                    </span>
                  </div>
                </li>
                <li className={styles.cart_item}>
                  <div className='d-flex'>
                    <span className={clsx(styles.index, 'd-block')}>
                      Phase 3: (week 2)
                    </span>
                    <span className={styles.week}>
                      ( {type === 'sample' ? '__' : 1} Week)
                    </span>
                  </div>
                  <div className='d-flex'>
                    <span className={styles.name}>Detailed Design</span>
                    <span className={styles.price}>
                      {type === 'sample'
                        ? '$_____'
                        : formatter.format(design ? design - 500 : 0)}
                    </span>
                  </div>
                </li>
                <li className={styles.cart_item}>
                  <div className='d-flex'>
                    <span className={clsx(styles.index, 'd-block')}>
                      Phase 4: (week 3-6)
                    </span>
                    <span className={styles.week}>
                      ( {type === 'sample' ? '__' : 4} Weeks)
                    </span>
                  </div>
                  <div className='d-flex pr-1'>
                    <span className={styles.name}>Development and Testing</span>
                    <span className={styles.price}>
                      {type === 'sample'
                        ? '$_____'
                        : formatter.format(development / 2)}
                    </span>
                  </div>
                  <div className='d-flex pr-1'>
                    <span className={styles.name}>Publish App</span>
                    <span className={styles.price}>
                      {type === 'sample'
                        ? '$_____'
                        : formatter.format(development / 2)}
                    </span>
                  </div>
                </li>
              </ul>
              <div className={styles.total}>
                <span>total Cost</span>
                <span className={styles.total_price}>
                  {type === 'sample' ? '$____' : formatter.format(total)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reciept;
