/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
import * as React from 'react';
import clsx from 'clsx';
import { BsXCircleFill } from 'react-icons/bs';
import styles from './styles.module.css';

function Modal({
  isOpen,
  setIsOpen = () => {},
  title,
  children,
  sm,
  lg,
  xl,
  isClosable = true,
  imgPreview,
  // width,
  helpModal,
  videoPreview,
  textSm,
  onClose = () => {},
}) {
  const modalRef = React.useRef();

  const closeModal = (e) => {
    if (modalRef.current === e.target && isClosable) {
      document.documentElement.style.overflow = 'visible';
      setIsOpen(false);
      onClose();
    }
  };

  const keyPress = React.useCallback(
    (e) => {
      if (e.key === 'Escape' && isOpen && isClosable) {
        setIsOpen(false);
        document.documentElement.style.overflow = 'visible';
      }
    },
    [setIsOpen, isOpen],
  );

  React.useEffect(() => {
    document.addEventListener('keydown', keyPress);
    return () => document.removeEventListener('keydown', keyPress);
  }, [keyPress]);

  React.useEffect(() => {
    if (isOpen) {
      document.documentElement.style.overflow = 'hidden';
    }
  }, [isOpen]);
  return (
    isOpen && (
      <div className={styles.Background} onClick={closeModal} ref={modalRef}>
        <div className={clsx(styles.ModalWrapper, videoPreview && styles.videoPreview,
          isOpen && styles.isOpen, xl && styles.xl, lg && styles.lg, sm && styles.sm, imgPreview && styles.imgPreview)}
        >
          <div
            className={styles.ModalContent}
            style={{
              background: helpModal ? 'var(--dark)' : 'var(--white)',
            }}
          >
            <div className={clsx(styles.flex)} style={{ marginBottom: !imgPreview && !videoPreview ? '1.875rem' : !title ? '0' : '' }}>
              {!imgPreview && title && <h3 className={clsx(styles.modalHeading, textSm && textSm, 'no-border text-center')}>{title}</h3>}
              {isClosable !== false && (
                <button
                  className={clsx(styles.CloseModalButton, !title ? styles.absolute : '')}
                  type="secondary"
                  shape="circle"
                  onClick={() => {
                    setIsOpen(!isOpen);
                    document.documentElement.style.overflow = 'visible';
                  }}
                  size={35}
                  style={{ flexShrink: '0' }}
                  absolute={!title}
                >
                  <i className="material-icons-outlined"><BsXCircleFill /></i>
                </button>
              )}
            </div>
            {children}
          </div>
        </div>
      </div>
    )
  );
}

export default Modal;
